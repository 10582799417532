<template>
<v-container fill-height :class="$vuetify.breakpoint.xsOnly ? 'onboard-bg-sm' : 'onboard-bg'">
    <v-row justify="center" align="center">
        <v-col cols="12" md="8" lg="6">
            <v-row no-gutters justify="center" align="center">
                <v-col cols="6">
                    <v-img src='@/assets/logo/petmeetly-text-color.png'></v-img>
                    <div class="text-center" style="letter-spacing:5px">ADMIN</div>
                </v-col>
            </v-row>

            <v-row justify="center" align="center">
                <v-col cols="11" class="pb-6">
                    <h2>Welcome Back!</h2>
                </v-col>
            </v-row>
            <v-form ref="loginForm" v-model="valid" lazy-validation @submit.prevent="login">
                <v-row justify="center" align="center">
                    <v-col cols="11" class="pb-0">
                        <!-- <v-text-field placeholder="Email address" background-color="#f3f3f3" v-model="email"  prepend-inner-icon="mdi-account-outline" :rules="emailRules" filled rounded dense></v-text-field>
                         <v-text-field placeholder="Password" background-color="#f3f3f3" v-model="password" type="password"  prepend-inner-icon="mdi-lock-outline" :rules="passwordRules" append-icon="mdi-lifebuoy" @click:append="forgotPassword" filled rounded dense></v-text-field> -->
                        <v-text-field flat solo background-color="#f3f3f3" v-model="email" label="Email" prepend-inner-icon="$vuetify.icons.account-icon" :rules="emailRules"></v-text-field>
                        <v-text-field flat solo background-color="#f3f3f3" v-model="password" type="password" label="Password" prepend-inner-icon="mdi-lock-outline" :rules="passwordRules"></v-text-field>
                        <v-btn large block color="primary" type="submit" :loading="submitting" :disabled="submitting">Login</v-btn>
                    </v-col>
                </v-row>
            </v-form>
        </v-col>
    </v-row>

    <v-snackbar v-model="snackbar">
        {{ snackbar_text }}
        <template v-slot:action="{ attrs }">
            <v-btn color="secondary" icon v-bind="attrs" @click="snackbar = false">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </template>
    </v-snackbar>

</v-container>
</template>

<script>


export default {
    name: 'Login',

    data() {
        return {

            email: '',
            password: '',

            valid: true,
            submitting: false,
            emailValidating: false,

            snackbar: false,
            snackbar_text: '',

            emailRules: [
                v => !!v || 'Email is required',
                v => /.+@.+/.test(v) || 'Email must be valid',
            ],

            passwordRules: [
                v => !!v || 'Password is required',
            ],
        }
    },

    mounted() {
        if (this.$store.getters.isLoggedIn) {
            this.$router.replace('/home');
        }
    },

    methods: {


        login() {

            if (this.$refs.loginForm.validate()) {
                this.submitting = true;
                let email = this.email
                let password = this.password

                this.$store.dispatch('login', {
                        email,
                        password
                    })
                    .then(() => {
                        this.submitting = false;


                    })
                    .catch(err => {

                        if (typeof err.response.data.error != "undefined") {
                            this.snackbar_text = err.response.data.error;
                        } else {
                            this.snackbar_text = "Invalid Email/Password";
                        }

                        this.snackbar = true;
                        this.submitting = false;
                    });

            }
        },

        
    }
}
</script>
